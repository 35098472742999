import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p>{`Bolet amb un capell blanc grisós molt pàl·lid de 5 a 12 cm de diàmetre que el podem trobar convex, pla i en forma d’embut quan és més major. Té el marge enrotllat i, amb freqüència ondulat. Davall aquest es troben nombroses làmines blanques que tornen roses per la caiguda de les espores. Aquestes són fusiformes amb sis ratlles, semblen hexagonals, de 8-14 x 5-6 micres. El peu, no gaire llarg, és mes gruixut i cotonós a la base i de color blanquinós.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      